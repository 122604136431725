import { openDB } from 'idb';

const DB_NAME = 'TradingDB';
const STORE_NAME_ORDERS = 'ordersStore';
const STORE_NAME_WALLET = 'walletStore';
const STORE_NAME_TRANSACTIONS = 'transactionsStore';

const dbPromise = openDB(DB_NAME, 2, {
    upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME_ORDERS)) {
            db.createObjectStore(STORE_NAME_ORDERS, { keyPath: 'id', autoIncrement: true });
        }
        if (!db.objectStoreNames.contains(STORE_NAME_WALLET)) {
            db.createObjectStore(STORE_NAME_WALLET, { keyPath: 'currency' });
        }
        if (!db.objectStoreNames.contains(STORE_NAME_TRANSACTIONS)) {
            db.createObjectStore(STORE_NAME_TRANSACTIONS, { keyPath: 'id', autoIncrement: true });
        }
    },
});

export const saveOrder = async (order) => {
    try {
        const db = await dbPromise;
        await db.put(STORE_NAME_ORDERS, order);
    } catch (error) {
        console.error('Failed to save order:', error);
        throw error;
    }
};

export const getOrders = async () => {
    try {
        const db = await dbPromise;
        return await db.getAll(STORE_NAME_ORDERS);
    } catch (error) {
        console.error('Failed to get orders:', error);
        throw error;
    }
};

export const deleteAllOrders = async () => {
    try {
        const db = await dbPromise;
        await db.clear(STORE_NAME_ORDERS);
    } catch (error) {
        console.error('Failed to delete all orders:', error);
        throw error;
    }
};

export const saveWallet = async (wallet) => {
    try {
        const db = await dbPromise;
        const tx = db.transaction(STORE_NAME_WALLET, 'readwrite');
        const store = tx.objectStore(STORE_NAME_WALLET);
        await store.put({ currency: 'USDT', amount: wallet.USDT });
        await store.put({ currency: 'BTC', amount: wallet.BTC });
        await store.put({ currency: 'ETH', amount: wallet.ETH });
        await tx.done;
    } catch (error) {
        console.error('Failed to save wallet:', error);
        throw error;
    }
};

export const getWallet = async () => {
    try {
        const db = await dbPromise;
        const tx = db.transaction(STORE_NAME_WALLET, 'readonly');
        const store = tx.objectStore(STORE_NAME_WALLET);
        const wallet = {
            USDT: (await store.get('USDT'))?.amount || 0,
            BTC: (await store.get('BTC'))?.amount || 0,
            ETH: (await store.get('ETH'))?.amount || 0,
        };
        await tx.done;
        return wallet;
    } catch (error) {
        console.error('Failed to get wallet:', error);
        throw error;
    }
};

export const deleteWallet = async () => {
    try {
        const db = await dbPromise;
        const tx = db.transaction(STORE_NAME_WALLET, 'readwrite');
        const store = tx.objectStore(STORE_NAME_WALLET);
        await store.clear();
        await tx.done;
    } catch (error) {
        console.error('Failed to delete wallet:', error);
        throw error;
    }
};
